import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import labelsController from "../../../../barrel/controllers/labelsController";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import CustomLoadingAction from "../../../../components/CustomLoadingAction";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import PrintLabelPopupConfirmationButtons from "./printLabelPopupConfirmationButtons/printLabelPopupConfirmationButtons";

import { TextField } from "@mui/material";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import HxfAppServersService from "../../../../barrel/services/hxfAppServersService";
import ZebraService from "../../../../barrel/services/zebraService";
import styles from "./printLabelPopup.module.scss";


interface IPrintLabelPopupPrintableLabel{
  Id_Label?:any;
  name?:any;
  code?:any;
  _mapInputsPrint?:any; //map inputs and its configs to ask before printing
}

interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  onTerminateButton?:any;
  terminateButtonVisible?:boolean;
  title: string;
  printableLabels:IPrintLabelPopupPrintableLabel[];
  genericLabelData:any;
  isZebra?:any;
  zebraLabelMode?:any;
  countToPrint?:any;
}
function PrintLabelPopup(props: IPopup) {

  const feedbackService = useFeedbackService();
  const [didMount, setDidMount] = useState(false);
  const [isLoading,setIsLoading] = useState(true);
  const timerCheckScanMobile = useRef<any>(null);
  const {t} = useHxfTranslation();
  const [currentPrintableLabelIndex, setCurrentPrintableLabelIndex] = useState(0);
  const [printButtonEnabled, setPrintButtonEnabled] = useState(true);
  const [renderPrintUrlKey, setRenderPrintUrlKey] = useState(0);

  const renderPrintUrl = useRef("");

  const [filledMapInputs, setFilledMapInputs] = useState<any>({});

  const getCurrentServerServe = () => {

    if (process.env.REACT_APP_MODE === "dev") {
      return process.env.REACT_APP_API_SERVE_DEV;
    } 

    let server = localStorage.getItem("srv");
    if (!server) {
      throw "x2Unexpected err, server has not been set yet";
    }

    
    return HxfAppServersService().getServerEndpoint(server);

  }


  const getCurrentLabelMapInputsPrintElement = () => {

    let idPrintableLabel = props.printableLabels[currentPrintableLabelIndex].Id_Label;

    let mapInputsPrint = props?.printableLabels[currentPrintableLabelIndex]?._mapInputsPrint;

    if(!mapInputsPrint){
      return (<></>)
    }
    let keysInputs = Object.keys(mapInputsPrint);

    if(keysInputs.length === 0){
      return (<></>)
    }

    let arrayInputs = [];
    for(let i= 0; i<keysInputs.length; i++){
      let key = keysInputs[i];

      arrayInputs.push({key:key});
    }

    return (
      <div className={styles.mapInputsPrint}>
        {arrayInputs.map((objInput:any, index:any) => (
          <div key={"labelCustomInput_"+ index} style={{marginBottom:10}}>
                <TextField
                        fullWidth
                        error={false}
                        onChange={(evt:any) => {
                          let key = objInput.key;
                          let newVal = evt.target.value;

                          let newMapInputs = {...filledMapInputs};
                          if(newMapInputs[key]){
                            newMapInputs[key] = {...newMapInputs[key]};
                          }else{
                            newMapInputs[key] = {};
                          }

                          newMapInputs[key].value = newVal;
                          setFilledMapInputs(newMapInputs);
                        }}
                        value={filledMapInputs?.[objInput?.key]?.value ? filledMapInputs?.[objInput?.key]?.value : ""}
                        label={objInput.key}
                        variant="outlined"
                      
                        
                  />
          </div>
        ))}
      </div>
    )
  }

  const requestGenerateZebra = (labelData:any) => {

    labelsController().generateZpl({...labelData}).then((res:any) => {
 
      let zplLines = res?.data?.response?.zplLabelConverted;
      if(!zplLines){
        throw "unexpected zpl label";
      }


      ZebraService().generateZplFile(zplLines);

    }).catch((res:any) => {
      feedbackService.notifyToast("Failed to fetch label. ", "error");
    });
  }
  
  const initiateCustomListenerForMobile = () => {
    timerCheckScanMobile.current = setTimeout(() => {
      let valFound = localStorage.getItem("webcom_rn");

      if (!valFound || valFound === "") {
        initiateCustomListenerForMobile();
      } else {
        try {
          let receivedMessage = JSON.parse(valFound);
          let action = receivedMessage.action;
          let payload = receivedMessage.payload;
          if (action === "wrapcom_network_print_zpl_return") {
            clearTimeout(timerCheckScanMobile.current);
         
           
            if(payload.printStatus === "success"){
            
              feedbackService.notifyToast(t('print.label.success'), "success");
            }else if(payload.printStatus === "failed"){
              feedbackService.notifyToast(t('print.label.fail'), "error");
            }
        
            setPrintButtonEnabled(true);
          
          }else{
            initiateCustomListenerForMobile();
          }

 
        } catch (exception) {
          alert("critical error occured when trying to read rn message");
          initiateCustomListenerForMobile();
        }

        localStorage.setItem("webcom_rn", "");
      }
    }, 250);
  };


  const callPrintZebraLabelsNetwork = (genericLabelData:any,numberOfLabels:number) => {
    labelsController().generateZpl(genericLabelData).then((res:any) => {
      let zplData = res?.data?.response?.zplLabelConverted;

      let zplLabelFull = "";
      for(let i = 0; i<zplData.length; i++){
        zplLabelFull += zplLabelFull + "" + zplData[i];
      }
    
      initiateCustomListenerForMobile();
      setPrintButtonEnabled(false);
      MobileWrapperCommunicationService().notifyPrintLabel(zplLabelFull,numberOfLabels);
   }).catch((res) => {
    feedbackService.notifyToast(t("generic.critical.error"), "error");
   });
  }




  const printLabel = () => {
  
  let idPrintableLabel = props.printableLabels[currentPrintableLabelIndex].Id_Label;
  let objExtra = props?.genericLabelData ? props.genericLabelData : {};
  let objParamsRender:any = {...objExtra};
  objParamsRender["Id_Label"] = idPrintableLabel;
  if(Object.keys(filledMapInputs).length > 0){
    objParamsRender["mapInputs"] = JSON.stringify(filledMapInputs);
  }

  let queryBuilder:any = null;
  ////window.open(labelRenderUrl, "_blank");

  if(props.isZebra || MobileWrapperCommunicationService().isDeviceMobileWrapper()){

      labelsController().getDirectViewToken(objParamsRender).then((res:any) => {
        //1 get file visual token
     
        if(props?.zebraLabelMode === "DIRECT_NETWORK_PRINT_ZPL"){
         
          let countToPrint = props?.countToPrint ? props.countToPrint : 1;
          callPrintZebraLabelsNetwork(objParamsRender,countToPrint);

        }else{
          let tokenDvt = res?.data?.response?.data?.token;
          if(!tokenDvt){
            throw "failed to fetch token";
          }
          objParamsRender["dvt"] = tokenDvt;
          queryBuilder = new URLSearchParams(objParamsRender);
  
          if(props.isZebra){
  
            let renderUrl:any = getCurrentServerServe() + "label/render-zpl/label.zpl?" + queryBuilder.toString() + "&x=label.zpl";
            window.location.href = renderUrl;
            //requestGenerateZebra({...objParamsRender,Id_Label:idPrintableLabel});
          }else{
            let renderUrl = getCurrentServerServe() + "label/render-pdf/label.pdf?" + queryBuilder.toString() + "&x=outputfile.pdf";
            console.log("GEN URL: ", renderUrl);
            MobileWrapperCommunicationService().notifyOpenPdf(renderUrl);
          }
        }


      }).catch((res:any) => {
        feedbackService.notifyToast("Failed to fetch token", "error");
      });
     
    }else{

      labelsController().getDirectViewToken(objParamsRender).then((res:any) => {
  
        //1 get file visual token
        let tokenDvt = res?.data?.response?.data?.token;
        if(!tokenDvt){
          throw "failed to fetch token";
        }
        objParamsRender["dvt"] = tokenDvt;
        queryBuilder = new URLSearchParams(objParamsRender);
        let renderUrl = getCurrentServerServe() + "label/print-pdf/?" + queryBuilder.toString() + "&x=outputfile.pdf";
        console.log("GEN URL: ", renderUrl);
        renderPrintUrl.current = renderUrl;
   
        setRenderPrintUrlKey(renderPrintUrlKey + 1);
      }).catch((res:any) => {
        feedbackService.notifyToast("Failed to fetch token", "error");
      });

      /*
      queryBuilder = new URLSearchParams(objParamsRender);
      let labelRenderUrl = getCurrentServerServe() +
      "label/print-pdf/?" +
      queryBuilder.toString();
  
      renderPrintUrl.current = labelRenderUrl;
      setRenderPrintUrlKey(renderPrintUrlKey + 1);*/
    }
  
   
  }
  /*
  if (!props?.productData) {
    return <></>;
  }
  */
  useEffect(() => {
    if(!didMount){
      //todo show 0/y, print, render label etc..
      setIsLoading(false);
      setDidMount(true);
    }
    
  },[didMount]);

  if(isLoading){
    return (
      <CustomLoadingAction/>
    )
  }

  return (
    <div className={styles.intPopup}>
      <SimpleContentPopup left={"0xp"}>
        <div key={"printLblPop_" + renderPrintUrlKey}>
              <iframe
              style={{display:'none'}}
              name="mylabel_print_output"
              src={
                renderPrintUrl.current
              }
              width="0"
              height="0"
              onLoad={() => {
                console.log("finished loading");

              }}
            ></iframe>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>

              <div className={styles.title}>{props.title}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>


            {props?.printableLabels?.length > 0 && (
              <>
                <div className={styles.selectedProductInfo}>
                  <div className={styles.subBarInfo}>
                    <div>
                      <div>
                      ({props.printableLabels[currentPrintableLabelIndex].code}) {props.printableLabels[currentPrintableLabelIndex].name} 
                      </div>

                      <div style={{display:'flex', justifyContent:'center',fontSize:'29px'}}>
                        ({currentPrintableLabelIndex + 1}/{props.printableLabels.length})
                      </div>
                    </div>
        
                  
                  </div>
                </div>


              </>
            )}
              {props.printableLabels.length === 0 && (
                <>
                  <div>
                    {t('no.labels.to.print')}
                  </div>
                </>
              )}
                <div className={styles.content}>
                  {getCurrentLabelMapInputsPrintElement()}
                </div>
            {/*
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
              <div className={styles.serialTxt}>
                <b> </b>
              </div>
              <div className={styles.serialCode}>
               
              </div>
                
            </div> */}
          </div>

            
          <PrintLabelPopupConfirmationButtons
            printButtonEnabled={printButtonEnabled && props.printableLabels.length > 0}
           terminateButtonVisible={props.terminateButtonVisible}
           previousEnabled={currentPrintableLabelIndex !== 0}
           nextEnabled={currentPrintableLabelIndex<props.printableLabels.length - 1}
           onClickNext={() => {
            setCurrentPrintableLabelIndex(currentPrintableLabelIndex + 1);
           }}
           onClickPrevious={() => {
            setCurrentPrintableLabelIndex(currentPrintableLabelIndex - 1);
           }}
           onClickPrint={() => {

            printLabel();
           }}
           onClickTerminate={() => {
             if(props?.onTerminateButton){
               props.onTerminateButton();
             }
           }}
          />
        
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default PrintLabelPopup;
